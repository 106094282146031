let started = false;

let connectedComponents = [];
let registeredComponents = {};

/**
 * @param {HTMLElement} element
 * @return {{start: start, connectedComponents: *[], registeredComponents: {}, register: register}|boolean}
 */
export function App(element = document.documentElement) {
	let observer = new MutationObserver((mutations) =>
		processMutations(mutations),
	);

	function start() {
		if (started) {
			return;
		}

		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', start);
		}

		observe();
		createComponents(element);
		document.removeEventListener('DOMContentLoaded', start);
		started = true;
	}

	/**
	 * @param {MutationRecord[]} mutations
	 */
	function processMutations(mutations) {
		unobserve();

		mutations.forEach((record) => {
			Array.from(record.addedNodes)
				.filter(isHTMLELement)
				.forEach((element) => createComponents(element));

			Array.from(record.removedNodes)
				.filter(isHTMLELement)
				.forEach((element) => removeComponents(element));
		});

		observe();
	}

	function observe() {
		observer.observe(element, {
			childList: true,
			subtree: true,
			attributes: true,
			attributeFilter: ['data-component'],
		});
	}

	function unobserve() {
		observer.takeRecords();
		observer.disconnect();
	}

	/**
	 * @param {string} name
	 * @param component
	 */
	function register(name, component) {
		if (isComponentRegistered(name)) {
			return;
		}

		registeredComponents[name] = component;

		if (started) {
			unobserve();
			createComponents(element, name);
			observe();
		}
	}

	/**
	 * @param {HTMLElement} element
	 * @param {string} name
	 */
	function createComponents(element, name) {
		getElements(element).map((element) =>
			getComponentNamesFromDataAttribue(element)
				.filter((n) => name === undefined || n === name)
				.forEach((name) => createComponent(element, name)),
		);
	}

	/**
	 * @param {HTMLElement} element
	 * @param {string} name
	 */
	function removeComponents(element, name) {
		getElements(element).map((element) =>
			getComponentNamesFromDataAttribue(element)
				.filter((n) => name === undefined || n === name)
				.forEach((name) => removeComponent(element, name)),
		);
	}

	/**
	 * @param {HTMLElement} element
	 * @param {string} name
	 */
	function createComponent(element, name) {
		if (!isComponentRegistered(name)) {
			return;
		}

		(getEntry(element) || createEntry(element)).add(name);
	}

	/**
	 * @param {HTMLElement} element
	 * @param {string} name
	 */
	function removeComponent(element, name) {
		let index = getEntryIndex(element);

		if (index < 0) {
			return;
		}

		connectedComponents[index].remove(name);
		connectedComponents.splice(index, 1);
	}

	function getElements(parent) {
		return [parent, ...parent.querySelectorAll('[data-component]')];
	}

	/**
	 * @param value
	 */
	function isHTMLELement(value) {
		return value instanceof HTMLElement;
	}

	/**
	 * @param {HTMLElement} element
	 * @return {number}
	 */
	function getEntryIndex(element) {
		return connectedComponents.findIndex((entry) => entry.element === element);
	}

	/**
	 * @param {HTMLElement} element
	 * @return {undefined|*}
	 */
	function getEntry(element) {
		let index = getEntryIndex(element);

		if (index < 0) {
			return undefined;
		}

		return connectedComponents[index];
	}

	/**
	 * @param {Element} element
	 * @return {{add(string): void, components: *[], getIndex(string): *|number, get(string): *|undefined, has(string): boolean, remove(string): void, element: Element}|number|*|undefined|boolean}
	 */
	function createEntry(element) {
		let entry = {
			element,
			components: [],

			/**
			 * @param {string} name
			 */
			get(name) {
				let i = this.getIndex(name);

				return i > -1 ? this.components[i] : undefined;
			},

			/**
			 * @param {string} name
			 */
			getIndex(name) {
				return this.components.findIndex((c) => c.name === name);
			},

			/**
			 * @param {string} name
			 */
			has(name) {
				return this.getIndex(name) > -1;
			},

			/**
			 * @param {string} name
			 */
			add(name) {
				if (this.has(name)) {
					return;
				}

				let component = registeredComponents[name](element);

				if (typeof component?.connect === 'function') {
					component.connect();
				}

				this.components.push({ name, component });
			},

			/**
			 * @param {string} name
			 */
			remove(name) {
				let index = this.getIndex(name);

				if (!(index > -1)) {
					return;
				}

				let component = this.components[index];

				if (typeof component?.disconnect === 'function') {
					component.disconnect();
				}

				this.components.splice(index, 1);
			},
		};

		connectedComponents.push(entry);

		return entry;
	}

	/**
	 * @param {string} name
	 * @return {boolean}
	 */
	function isComponentRegistered(name) {
		return Object.prototype.hasOwnProperty.call(registeredComponents, name);
	}

	/**
	 * @param {HTMLElement} element
	 * @return {string[]|*[]}
	 */
	function getComponentNamesFromDataAttribue(element) {
		if (
			!(element instanceof HTMLElement) ||
			!element.hasAttribute('data-component')
		) {
			return [];
		}

		return element.dataset.component.split(/\s+/);
	}

	return {
		start,
		register,
		connectedComponents,
		registeredComponents,
	};
}
