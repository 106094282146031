import boatfolkBars from '@src/icons/boatfolk-bars-map-marker.svg';
import theCreek from '@src/icons/the-creek-map-marker.svg';
import theDeck from '@src/icons/the-deck-map-marker.svg';

let map;

// Build map
async function initMap(target) {
  const lat = target.dataset.lat;
  const lng = target.dataset.lng;

  const markerSvg =
  	target.dataset.marker == 'theCreek' ? theCreek
  	: target.dataset.marker == 'theDeck' ? theDeck
  	: boatfolkBars;

  const position = { lat: parseFloat(lat), lng: parseFloat(lng) };
  // Request needed libraries.
  const { Map } = await google.maps.importLibrary("maps");

  // The map
  map = new Map(target, {
    zoom: parseFloat(target.dataset.zoom),
    center: position,
    disableDefaultUI: false,
  });

  // The markers
  const markers = getMarkers(target);

  for (let i = 0; i < markers.length; i++) {
  	const infowindow = new google.maps.InfoWindow({
      content: markers[i].label
    });

	  const marker = new google.maps.Marker({
	    map: map,
	    position: {
	    	lat: parseFloat(markers[i].lat),
	    	lng: parseFloat(markers[i].lng)
	    },
	    icon: markerSvg,
	    title: markers[i].label,
	  });

	  marker.addListener("click", () => {
	    infowindow.open({
	      anchor: marker,
	      map,
	    });
	  });
	}
}

let getMarkers = function (e) {
  let markers = [];

  if(!e.parentNode) {
    return markers;
  }

  let sibling  = e.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      markers.push({ 'lat': sibling.dataset.lat, 'lng': sibling.dataset.lng, 'label': sibling.dataset.label });
    }
    sibling = sibling.nextSibling;
  }

  return markers;
};

// Look for all map containers
const buildMaps = window.addEventListener(
  "load",
  (event) => {
    document.querySelectorAll(".google-map").forEach(function (i) {
      if (i) {
        initMap(i);
      }
    });
  },
  false,
);

export { buildMaps };
