import { dialog, domapi } from '@rynpsc/dialog';
import { lock, unlock } from './scroll-lock';

domapi.mount();

/**
 * @param {HTMLElement} element
 */
export function Drawer(element) {
	let dialogInstance = dialog(element.id);

	if (dialogInstance === undefined) {
		return;
	}

	dialogInstance.create();
	let contents = element.querySelector('.js-drawer-contents');
	element.addEventListener('click', handleDrawerClick);

	/**
	 * @param {MouseEvent} event
	 */
	function handleDrawerClick(event) {
		if (contents?.contains(event.target)) {
			return;
		}

		dialogInstance?.close();
	}

	dialogInstance.on('open', lock);
	dialogInstance.on('close', unlock);
}
