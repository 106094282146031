let element = document.body;
let fixedElementSelector = '.c-cookie-notice';

export function lock() {
	let width = getWidth();

	let computedStyle = window.getComputedStyle(element);
	let paddingRight = parseInt(computedStyle.paddingRight, 10);

	element.style.overflow = 'hidden';
	element.style.paddingRight = `${paddingRight + width}px`;

	Array.from(document.querySelectorAll(fixedElementSelector)).forEach(
		(element) => {
			let computedStyle = window.getComputedStyle(element);
			element.setAttribute('data-right', element.style.right);

			let right = parseInt(computedStyle.right, 10);
			element.style.right = `${right + width}px`;
		},
	);
}

export function unlock() {
	element.removeAttribute('style');

	Array.from(document.querySelectorAll(fixedElementSelector)).forEach(function (
		element,
	) {
		element.style.right = element.getAttribute('data-right');
		element.removeAttribute('data-right');
	});
}

/**
 * @return {number}
 */
function getWidth() {
	// https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#usage_notes
	let documentWidth = document.documentElement.clientWidth;
	return Math.abs(window.innerWidth - documentWidth);
}
