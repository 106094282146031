import Cookies from 'js-cookie';
import { queryAll } from '../utils/dom';

/**
 * @param {HTMLElement} element
 */
export function Alert(element) {
	let cookieName = element.dataset.id;

	let controls = queryAll(element, '.js-alert-dismiss');

	controls.forEach((elem) => {
		elem.addEventListener('click', controlClick);
	});

	/** @type Cookies.CookieAttributes */
	let cookieOptions = {
		sameSite: 'None',
		secure: true,
	};

	if (element.dataset.expiry) {
		cookieOptions.expires = new Date(element.dataset.expiry);
	}

	/**
	 * @param {MouseEvent} event
	 */
	function controlClick(event) {
		event.preventDefault();

		if (cookieName) {
			Cookies.set(cookieName, '1', cookieOptions);
		}

		element.remove();
	}
}
