/**
 * @param {HTMLElement} element
 */
export function Disclosure(element) {
	const CLASS_READY = 'is-ready';
	const CLASS_EXPANDED = 'is-expanded';

	let isExpanded = element.classList.contains(CLASS_EXPANDED);
	let trigger = element.querySelector('.js-disclosure-trigger');
	let controls = trigger.dataset.controls;

	element.classList.add(CLASS_READY);

	trigger.addEventListener('click', handleClick);
	trigger.addEventListener('keydown', handleKeydown);
	trigger.removeAttribute('data-controls');
	trigger.setAttribute('aria-expanded', String(isExpanded));

	if (trigger) {
		trigger.setAttribute('aria-controls', controls);
	}

	element.classList.toggle(CLASS_EXPANDED, isExpanded);

	/**
	 * @param {MouseEvent} event
	 */
	function handleClick(event) {
		toggle();
		event.preventDefault();
	}

	/**
	 * @param {KeyboardEvent} event
	 */
	function handleKeydown(event) {
		switch (event.code) {
			case 'ArrowDown':
				open();
				event.preventDefault();
				break;
			case 'ArrowUp':
				close();
				event.preventDefault();
				break;
			case 'Space':
				toggle();
				event.preventDefault();
				break;
			// no default
		}
	}

	function open() {
		isExpanded = true;
		element.classList.add(CLASS_EXPANDED);
		trigger.setAttribute('aria-expanded', 'true');
	}

	function close() {
		isExpanded = false;
		element.classList.remove(CLASS_EXPANDED);
		trigger.setAttribute('aria-expanded', 'false');
	}

	function toggle(toggle = !isExpanded) {
		return toggle ? open() : close();
	}
}
