import 'vite/modulepreload-polyfill';
import { Alert } from './modules/alert';
import { App } from './app';
import { Carousel } from './modules/carousel';
import { Disclosure } from './modules/disclosure';
import { Drawer } from './modules/drawer';
import { LazyLoader } from './modules/lazy-loader';
import { NavigationBar } from './modules/navigation-bar';
import { VideoEmbed } from './modules/video-embed';
import { Video } from './modules/video';
import { buildMaps } from './modules/map.js';

let app = App();


let lazyLoader = LazyLoader();
lazyLoader.update();

app.start();
app.register('drawer', Drawer);
app.register('disclosure', Disclosure);
app.register('alert', Alert);
app.register('navigation-bar', NavigationBar);
app.register('video', Video);
app.register('video-embed', VideoEmbed);
app.register('carousel', Carousel);
