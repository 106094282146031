import { clamp, isBetween } from '../utils/number';

/**
 * @typedef {Object} Options
 * @property {string} prevSelector
 * @property {string} NextSelector
 * @property {string} trackSelector
 * @property {string} stepsSelector
 */

/**
 * @typedef {Object} ItemObject
 * @property {number} index
 * @property {Function} scrollTo
 * @property {HTMLElement} element
 * @property {{ size: number; start: number; end: number }} rect
 */

/** @type {Options} */
const defaultOptions = {
	prevSelector: '.js-carousel-prev',
	nextSelector: '.js-carousel-next',
	trackSelector: '.js-carousel-items',
	stepsSelector: '.js-carousel-steps',
};

/**
 * @param {HTMLElement} rootElement
 * @param {Options} options
 */
export function Carousel(rootElement, options = {}) {
	let config = { ...defaultOptions, ...options };

	/** @type {ItemObject[]} */
	let items;

	/** @type {ItemObject} */
	let currentItem;

	let steps = [];
	let activeStep = 0;

	let scrollRoot = rootElement.querySelector(config.trackSelector);

	if (scrollRoot === null) {
		return;
	}

	scrollRoot.addEventListener('scroll', scrollRootScroll);

	let prevControl = rootElement.querySelector(config.prevSelector);
	let nextControl = rootElement.querySelector(config.nextSelector);
	let paginationContainer = rootElement.querySelector(config.stepsSelector);
	let controlAlignmentRef = rootElement.querySelector(
		'.js-slider-prev-next-alignment-ref',
	);

	scrollRoot.setAttribute('tabindex', '0');
	prevControl?.addEventListener('click', () => goToRelative(-1));
	nextControl?.addEventListener('click', () => goToRelative(1));

	items = Array.from(scrollRoot.children).map((element, index) =>
		Item(element, index),
	);

	window.addEventListener('DOMContentLoaded', () => {
		items.forEach((item) => item.calculateRect());
		currentItem = getClosestItem(scrollRoot.scrollLeft);
		createPagination();
		scrollRootScroll();
		positionPrevNext();
	});

	window.addEventListener('resize', () => {
		items.forEach((item) => item.calculateRect());
		createPagination();
		setActiveStep();
	});

	rootElement.addEventListener('keydown', elementKeydown);

	/**
	 * @param {number} value
	 */
	function getItem(value) {
		return items[value] || null;
	}

	/**
	 * @param {number} amount
	 */
	function goToRelative(amount) {
		let index = clamp(currentItem.index + amount, 0, items.length - 1);

		currentItem = getItem(index);
		currentItem.scrollTo();
	}

	/**
	 * @param {number} position
	 * @return {*}
	 */
	function getClosestItem(position) {
		let index = 0;
		/** @type {number|undefined} */
		let minDelta;

		for (let i = 0; i < items.length; i++) {
			let delta = Math.abs(position - items[i].rect.start);

			if (minDelta !== undefined && delta > minDelta) {
				continue;
			}

			index = i;
			minDelta = delta;
		}

		return getItem(index);
	}

	function scrollRootScroll() {
		let scrollMin = 0;
		let scrollMax = scrollRoot.scrollWidth - scrollRoot.clientWidth;

		let position = scrollRoot.scrollLeft;
		let scrollable = scrollRoot.scrollWidth > scrollRoot.clientWidth;

		rootElement.classList.toggle('is-scrollable', scrollable);
		rootElement.classList.toggle('is-not-scrollable', !scrollable);

		prevControl?.classList.toggle('hidden', !scrollable);
		nextControl?.classList.toggle('hidden', !scrollable);
		paginationContainer?.classList.toggle('hidden', !scrollable);

		prevControl?.classList.toggle('is-disabled', position <= scrollMin);
		nextControl?.classList.toggle('is-disabled', position >= scrollMax);

		currentItem = getClosestItem(position);
		setActiveStep();
	}

	function positionPrevNext() {
		if (!controlAlignmentRef) {
			return;
		}

		let rootRect = rootElement.getBoundingClientRect();
		let referenceRect = controlAlignmentRef.getBoundingClientRect();

		let top = referenceRect.top - rootRect.top + referenceRect.height / 2;

		prevControl.style.top = `${top}px`;
		nextControl.style.top = `${top}px`;
	}

	/**
	 * @param {KeyboardEvent} event
	 */
	function elementKeydown(event) {
		let { key } = event;

		switch (key) {
			case 'ArrowLeft':
				goToRelative(-1);
				event.preventDefault();
				break;
			case 'ArrowRight':
				goToRelative(1);
				event.preventDefault();
				break;
			default: // no default
		}
	}

	function createPagination() {
		if (!paginationContainer) {
			return;
		}

		let count = 0;
		let runningWidth = 0;
		let viewportWidth = scrollRoot.clientWidth;
		let referenceWidth = viewportWidth;

		for (let i = 0; i < items.length; i++) {
			let { rect } = items[i];

			runningWidth += rect.end - rect.start;

			if (runningWidth > referenceWidth) {
				count++;
				referenceWidth += viewportWidth;
			}
		}

		steps = [];
		paginationContainer.innerHTML = '';

		for (let i = 0; i <= count; i++) {
			let a = document.createElement('span');
			paginationContainer.appendChild(a);
			a.classList.add('c-carousel__page');
			steps.push(a);
			a.addEventListener('click', () => {
				scrollRoot.scrollTo({
					left: i * viewportWidth,
					behavior: getScrollBehaviour(),
				});
			});
		}
	}

	function setActiveStep() {
		let t = 0;

		if (steps.length === 0) {
			return;
		}

		let { scrollLeft, clientWidth } = scrollRoot;

		for (let i = 0; i < steps.length; i++) {
			// The -1 is to account for rounding errors in scrollLeft and clientWidth.
			// In testing this accounts for instances when scrollLeft is rounded up or down.
			let start = i * clientWidth - 1;
			let end = start + clientWidth - 1;

			if (isBetween(scrollLeft, start, end, true)) {
				t = i;
				break;
			}
		}

		steps[activeStep].classList.remove('is-active');
		activeStep = t;
		steps[activeStep].classList.add('is-active');
	}

	/**
	 * @return {ScrollBehavior}
	 */
	function getScrollBehaviour() {
		let rootStyle = window.getComputedStyle(scrollRoot);

		if (!rootStyle.scrollBehavior) {
			return 'auto';
		}

		return rootStyle.scrollBehavior;
	}

	/**
	 * @param element
	 * @param index
	 * @return {ItemObject}
	 */
	function Item(element, index) {
		let rect = { size: 0, start: 0, end: 0 };

		/** @type {ItemObject} item */
		let item = {
			rect,
			element,
			calculateRect,
			scrollTo,
			index,
		};

		function calculateRect() {
			let { clientWidth, offsetLeft } = element;

			rect.size = clientWidth;
			rect.start = offsetLeft;
			rect.end = offsetLeft + clientWidth;
		}

		function scrollTo() {
			console.log(item);

			scrollRoot.scrollTo({
				top: 0,
				left: rect.start,
				behavior: getScrollBehaviour(),
			});

			currentItem = item;
			rootElement.dispatchEvent(new CustomEvent('slider:scroll-to'));
		}

		return item;
	}
}
