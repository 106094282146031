/**
 * @param {Document|HTMLElement} root
 * @param {string} selector
 */
export function queryAll(root = document, selector) {
	return Array.from(root.querySelectorAll(selector));
}

/**
 * @param {string} tag
 * @param {Object.<string, string>} attributes
 * @return {HTMLElement}
 */
export function createElement(tag, attributes = {}) {
	let element = document.createElement(tag);

	Object.entries(attributes).forEach(([key, value]) => {
		element.setAttribute(key, value);
	});

	return element;
}
