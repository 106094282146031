import { createElement, queryAll } from '../utils/dom';

export function NavigationBar() {
	queryAll(undefined, '.js-navigation-item').forEach((el) =>
		NavigationItem(el),
	);
}

/**
 * @param {HTMLElement} element
 */
function NavigationItem(element) {
	let isOpen = false;

	let trigger = element.querySelector('.js-navigation-trigger');

	if (trigger === null) {
		return;
	}

	document.addEventListener('click', handleDocumentInteraction);
	document.addEventListener('focusin', handleDocumentInteraction);

	let button = createElement('button', {
		type: 'button',
		class: trigger.className,
		'aria-expanded': String(isOpen),
		'aria-controls': trigger.dataset.controls,
	});

	button.innerHTML = trigger.innerHTML;
	trigger.insertAdjacentElement('afterend', button);
	trigger.remove();

	button.addEventListener('click', () => toggle());

	function toggle(toggle = !isOpen) {
		toggle ? open() : close();
	}

	function open() {
		isOpen = true;
		button.setAttribute('aria-expanded', 'true');
		element.classList.add('is-expanded');
	}

	function close() {
		isOpen = false;
		element.classList.remove('is-expanded');
		button.setAttribute('aria-expanded', 'false');
	}

	/**
	 * @param {FocusEvent|MouseEvent} event
	 */
	function handleDocumentInteraction(event) {
		/** @type {HTMLElement} event.target */
		if (element.contains(event.target)) {
			return;
		}

		close();
	}
}
