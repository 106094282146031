/**
 * Clamps a number between a lower and upper value.
 *
 * @param {number} rawValue
 * @param {number|undefined|null} lower
 * @param {number|undefined|null} upper
 * @return {number}
 */
export function clamp(rawValue, lower, upper) {
	let value = rawValue;

	if (upper !== undefined && upper !== null) {
		value = value <= upper ? value : upper;
	}

	if (lower !== undefined && lower !== null) {
		value = value >= lower ? value : lower;
	}

	return value;
}

/**
 * Checks if a number is between a lower and upper value.
 *
 * @param {number} value
 * @param {number} lower
 * @param {number} upper
 * @param {boolean} inclusive
 * @return {boolean}
 */
export function isBetween(value, lower, upper, inclusive = true) {
	if (inclusive) {
		return value >= lower && value <= upper;
	}

	return value > lower && value < upper;
}
