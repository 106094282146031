const defaults = {
	root: null,
	rootMargin: '30%',
	threshold: 0,
	srcAttr: 'data-src',
	srcsetAttr: 'data-srcset',
	selector: '[data-src], [data-srcset]',
	onLoad: (element) => element.classList.add('has-loaded'),
	onError: (element) => element.classList.add('has-errored'),
};

export function LazyLoader(options = {}) {
	const config = { ...defaults, ...options };

	const intersectionObserver = new IntersectionObserver(onIntersection, {
		root: config.root,
		rootMargin: config.rootMargin,
		threshold: config.threshold,
	});

	const resizeObserver = new ResizeObserver(onResize);
	const mutationObserver = new MutationObserver(onMutate);

	function onMutate() {
		getElements().forEach((elem) => intersectionObserver.observe(elem));
	}

	/**
	 * @param {ResizeObserverEntry[]} entries
	 */
	function onResize(entries) {
		for (let entry of entries) {
			let width = getWidth(entry.target, entry.contentRect.width);
			setSizes(entry.target, `${width}px`);
		}
	}

	mutationObserver.observe(document, {
		childList: true,
		subtree: true,
		attributes: true,
	});

	/** @type {Element[]} elements */
	let elements = [];

	function getElements() {
		return Array.from(document.querySelectorAll(config.selector));
	}

	function update() {
		elements = getElements();

		elements.forEach((elem) => {
			resizeObserver.observe(elem);
			intersectionObserver.observe(elem);
		});
	}

	/**
	 * @param {IntersectionObserverEntry[]} entries
	 */
	function onIntersection(entries) {
		entries
			.filter((entry) => entry.isIntersecting)
			.map((entry) => load(entry.target));
	}

	/**
	 * @param {HTMLElement} element
	 */
	function setAttributes(element) {
		const { srcAttr, srcsetAttr } = config;

		const src = element.getAttribute(srcAttr);
		const srcset = element.getAttribute(srcsetAttr);

		if (srcset && element) {
			element.srcset = srcset;
			element.removeAttribute(srcsetAttr);
		}

		if (src) {
			element.src = src;
			element.removeAttribute(srcAttr);
		}
	}

	/**
	 * @param {HTMLElement} element
	 */
	function load(element) {
		const index = elements.indexOf(element);

		element.onload = () => config.onLoad(element);
		element.onerror = () => config.onError(element);

		if (!element.getAttribute('sizes')) {
			setSizes(element, `${getWidth(element, element.clientWidth)}px`);
		}

		setAttributes(element);

		elements.splice(index, 1);
		intersectionObserver.unobserve(element);
	}

	return { update };
}

/**
 * @param {element} element
 * @param {number} width
 * @return {number}
 */
function getWidth(element, width) {
	let { parentNode } = element;

	if (width >= 40 || parentNode === null) {
		return width;
	}

	return getWidth(parentNode);
}

/**
 * @param {Element} element
 * @param {string} sizes
 */
function setSizes(element, sizes) {
	let { parentNode } = element;

	if (element instanceof HTMLImageElement) {
		element.sizes = sizes;
	}

	if (parentNode instanceof HTMLPictureElement) {
		let sources = Array.from(parentNode.querySelectorAll('source'));

		sources.forEach((source) => {
			source.sizes = sizes;
		});
	}
}
